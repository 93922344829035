<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1>Add New Reward</h1>
          <v-card class="mt-4">
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.name"
                      :rules="nameRules"
                      label="Reward Name"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model.number="form.points"
                      :rules="pointsRules"
                      label="Points"
                      type="number"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.color"
                      :rules="colorRules"
                      label="Color (Hex)"
                      hint="e.g., #0070BA"
                      persistent-hint
                      outlined
                    >
                      <template v-slot:append>
                        <v-menu top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon :color="form.color">mdi-eyedropper</v-icon>
                            </v-btn>
                          </template>
                          <v-color-picker
                            v-model="form.color"
                            flat
                            mode="hexa"
                          ></v-color-picker>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="form.cashoutMethod"
                      :items="cashoutMethods"
                      :rules="[(v) => !!v || 'Cashout method is required']"
                      label="Cashout Method"
                      required
                      outlined
                    ></v-select>
                  </v-col>
  
                  <v-col cols="12" md="6" v-if="form.cashoutMethod === 'giftCard'">
                    <v-text-field
                      v-model="form.vendor"
                      :rules="vendorRules"
                      label="Vendor"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="countries"
                      label="Country"
                      item-text="_id"
                      item-value="_id"
                      chip
                      clearable
                      outlined
                      deletable-chips
                      persistent-hint
                      v-model="form.country"
                    ></v-autocomplete>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="form.status"
                      :true-value="'active'"
                      :false-value="'inactive'"
                      label="Status"
                      color="primary"
                    ></v-switch>
                  </v-col>
  
                  <v-col cols="12">
                    <v-textarea
                      v-model="form.description"
                      :rules="descriptionRules"
                      label="Description"
                      required
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :disabled="!valid || loading"
                      :loading="loading"
                      @click="submitForm"
                    >
                      Add Reward
                    </v-btn>
                    <v-btn color="error" class="ml-4" @click="resetForm">
                      Reset
                    </v-btn>
                    <v-btn color="grey" text class="ml-4" @click="goBack">
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import RestResourceMobile from "../../../services/dataServiceMobile";
  const service = new RestResourceMobile();
  
  import RestResourceMobileV2 from "../../../services/dataServiceMobileV2";
  const servicev2 = new RestResourceMobileV2();
  
  export default {
    name: "AddReward",
  
    data() {
      return {
        valid: true,
        loading: false,
        countries: [],
        form: {
          name: "",
          points: null,
          color: "#0070BA",
          description: "",
          cashoutMethod: "",
          country: "",
          status: "active",
          vendor: "" // Added vendor field
        },
        cashoutMethods: [
          { text: "Bank Transfer", value: "bankTransfer" },
          { text: "PayPal", value: "paypal" },
          { text: "GiftCard", value: "giftCard" },
        ],
        nameRules: [
          (v) => !!v || "Name is required",
          (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
        ],
        pointsRules: [
          (v) => !!v || "Points are required",
          (v) => (v && v > 0) || "Points must be greater than 0",
        ],
        colorRules: [
          (v) => !!v || "Color is required",
          (v) =>
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v) ||
            "Color must be a valid hex code",
        ],
        descriptionRules: [
          (v) => !!v || "Description is required",
          (v) =>
            (v && v.length <= 200) ||
            "Description must be less than 200 characters",
        ],
        countryRules: [(v) => !!v || "Country is required"],
        vendorRules: [
          (v) => 
            (this.form.cashoutMethod !== "giftCard" || !!v) || 
            "Vendor is required for gift cards"
        ]
      };
    },
  
    mounted() {
      this.getCountryList();
    },
  
    methods: {
      async getCountryList() {
        try {
          const data = await service.getCountryList();
          console.log("list=>>", data.data);
          this.countries = data.data;
        } catch (error) {
          console.error("Error fetching country list:", error);
        }
      },
      async submitForm() {
        if (this.$refs.form.validate()) {
          try {
            this.loading = true;
            this.$setLoader();
  
            const rewardData = { ...this.form };
            // Remove vendor if not giftCard
            if (rewardData.cashoutMethod !== "giftCard") {
              delete rewardData.vendor;
            }
            await servicev2.addReward(rewardData);
  
            this.$router.push({ name: 'MobileV2Rewards' });
          } catch (error) {
            console.error("Error creating reward:", error);
          } finally {
            this.loading = false;
            this.$disableLoader();
          }
        }
      },
  
      resetForm() {
        this.$refs.form.reset();
        this.form.status = "active";
        this.form.vendor = ""; // Reset vendor as well
      },
  
      goBack() {
        this.$router.push({ name: 'MobileV2Rewards' });
      },
    },
  };
  </script>
  
  <style scoped>
  h1 {
    color: #1976d2;
    margin-bottom: 20px;
  }
  
  .v-card {
    padding: 20px;
  }
  </style>